@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");

body {
  font-family: "Nunito", sans-serif;
  overflow-x: hidden !important;
  background: rgb(238, 174, 202);
  background: linear-gradient(
    135deg,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
}

::-webkit-scrollbar {
  width: 0.2rem;
  border-radius: 0.6rem;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.6rem;
  background-color: #21222c;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #363536;
}
